import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from '../../../common/components/link/internal-link';
import RecentPostCarousel from '../recent-post-carousel';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './recent-posts-mobile.scss';

const RecentPostsMobile = ({ t, posts, title, showAllPostsLink }) =>
  isEmpty(posts) ? null : (
    <div className={classNames(styles.container, 'blog-card-background-color', 'blog-text-color')}>
      {title && (
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>
          {showAllPostsLink && (
            <Link to="/" className={styles.link} data-hook="recent-posts-mobile__link">
              {t('recent-posts.all-posts-link')}
            </Link>
          )}
        </div>
      )}
      <RecentPostCarousel posts={posts} />
    </div>
  );

RecentPostsMobile.propTypes = {
  t: PropTypes.func,
  posts: PropTypes.array.isRequired,
  showAllPostsLink: PropTypes.bool.isRequired,
};

export default withTranslate(RecentPostsMobile);
